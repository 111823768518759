import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { APP_LANGUAGES, DEFAULT_LOCALE } from '@core/constants';

Vue.use(VueI18n);

function loadLocaleMessages() {
  return APP_LANGUAGES.reduce((localeMessages, language) => {
    // eslint-disable-next-line global-require, import/no-dynamic-require
    localeMessages[language] = require(`../locales/${language}.json`);
    return localeMessages;
  }, {});
}

const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      month: 'long',
      day: 'numeric',
      weekday: 'long'
    }
  },
  es: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      month: 'long',
      day: 'numeric',
      weekday: 'long'
    }
  },
};

export default new VueI18n({
  locale: DEFAULT_LOCALE || 'es',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  dateTimeFormats
});
