import Vue from 'vue';
import Vuex from 'vuex';

import { mainModule } from '@main/module';
import { plugins } from './plugins';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    main: mainModule,
  },
  plugins,
  strict: process.env.NODE_ENV !== 'production'
});
