import Vue from 'vue';
import { router } from '@core/router';
import { store } from '@core/store';
import { plugins } from '@core/plugins';
import App from './App.vue';

Vue.config.productionTip = false;

new Vue({
  i18n: plugins.i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
