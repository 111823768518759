<template>
  <div>
      <nav-bar/>
  </div>
</template>
<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import { Components } from '@core/helpers/decorators';
import NavBar from './components/NavBar.vue';

@Component
@Components({ NavBar })
export default class Main extends Vue {}
</script>
