<i18n>
  {
    "en": {
      "oops": "Oops!",
      "not-found": "404 Not Found",
      "not-found-extra": "Sorry, an error has occured, Requested page not found!",
      "home": "Take Me Home"
    },
    "es": {
      "oops": "¡Ups!",
      "not-found": "404 No encontrado",
      "not-found-extra": "Ha habido un error y la página no se ha encontrado",
      "home": "Llévame a la página principal"
    }
  }
</i18n>

<template>
  <div class="not-found pt-5">
    <div class="row">
        <div class="col-md-12">
            <div class="error-template">
                <h1>{{this.$t('oops')}}</h1>
                <h2>{{$t('not-found')}}</h2>
                <div class="error-details mb-3">
                    {{$t('not-found-extra')}}
                </div>
                <div class="error-actions">
                    <router-link to="/" class="btn btn-primary btn-lg"><span class="glyphicon glyphicon-home"></span>
                        {{$t('home')}} </router-link>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Component from 'vue-class-component';
import Vue from 'vue';

@Component
export default class NotFoundPage extends Vue {
}
</script>

<style scoped>
.not-found {
  height: 100%;
  width: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAxMC8yOS8xMiKqq3kAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzVxteM2AAABHklEQVRIib2Vyw6EIAxFW5idr///Qx9sfG3pLEyJ3tAwi5EmBqRo7vHawiEEERHS6x7MTMxMVv6+z3tPMUYSkfTM/R0fEaG2bbMv+Gc4nZzn+dN4HAcREa3r+hi3bcuu68jLskhVIlW073tWaYlQ9+F9IpqmSfq+fwskhdO/AwmUTJXrOuaRQNeRkOd5lq7rXmS5InmERKoER/QMvUAPlZDHcZRhGN4CSeGY+aHMqgcks5RrHv/eeh455x5KrMq2yHQdibDO6ncG/KZWL7M8xDyS1/MIO0NJqdULLS81X6/X6aR0nqBSJcPeZnlZrzN477NKURn2Nus8sjzmEII0TfMiyxUuxphVWjpJkbx0btUnshRihVv70Bv8ItXq6Asoi/ZiCbU6YgAAAABJRU5ErkJggg==);
  }
</style>
