import { getters } from './getters';
import * as actions from './actions';
import { mutations } from './mutations';
import { initialState } from './models';

export const mainModule = {
  state: initialState,
  actions,
  mutations,
  getters,
  modules: {
  },
};
