import { createDecorator } from 'vue-class-component';

import { mapActions, mapGetters } from 'vuex';

export function MapGetters(getterNames) {
  return createDecorator((options) => {
    if (!options.computed) options.computed = {};
    Object.assign(options.computed, mapGetters(getterNames));
  });
}

export function MapActions(actionNames) {
  return createDecorator((options) => {
    if (!options.methods) options.methods = {};
    Object.assign(options.methods, mapActions(actionNames));
  });
}

export function Components(componentNames) {
  return createDecorator((options) => {
    if (!options.components) options.components = {};
    Object.assign(options.components, componentNames);
  });
}

export function Mixins(mixinNames) {
  return createDecorator((options) => {
    if (!options.mixins) options.mixins = [];
    options.mixins.push(...mixinNames);
  });
}

export function Name(target, key) {
  createDecorator((componentOptions, k) => {
    componentOptions.name = k;
  })(target, key);
}
