<template>
  <b-navbar toggleable="lg" type="dark" variant="info">
    <b-navbar-toggle target="nav-collapse"/>
    <b-navbar-brand href="#">NavBar</b-navbar-brand>
    <div></div>
    <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
            <div class="d-flex justify-content-start">
            <b-nav-item href="#">Link</b-nav-item>
            </div>
            <div class="d-flex justify-content-start">
            <b-nav-item href="#" disabled>Disabled</b-nav-item>
            </div>
        </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import Component from 'vue-class-component';
import Vue from 'vue';

@Component
export default class NavBar extends Vue {}
</script>
